import layout from '@/layout'

export default {
  path: '/user',
  component: layout,
  redirect: '/user/info',
  name: 'userCenter',
  meta: {
    title: 'user',
    icon: 'personnel'
  },
  children: [
    {
      path: 'info',
      component: () =>
        import(/* webpackChunkName: "user-info" */ '@/views/user/info/index'),
      meta: {
        isUCenter: true,
        title: 'userInfo',
        icon: ''
      }
    },
    {
      path: 'release',
      component: () =>
        import(
          /* webpackChunkName: "user-release" */ '@/views/user/release/index'
          ),
      meta: {
        isUCenter: true,
        title: 'release',
        icon: ''
      }
    },
    {
      path: 'appointment',
      component: () =>
        import(
          /* webpackChunkName: "user-appointment" */ '@/views/user/appointment/index'
          ),
      meta: {
        isUCenter: true,
        title: 'appointment',
        icon: ''
      }
    },
    {
      path: 'reservaapply',
      component: () =>
        import(
          /* webpackChunkName: "user-reservaapply" */ '@/views/user/reservaapply/index'
          ),
      meta: {
        isUCenter: true,
        title: 'reservaapply',
        icon: ''
      }
    },
    {
      path: 'contract',
      component: () =>
        import(
          /* webpackChunkName: "user-contract" */ '@/views/user/contract/index'
          ),
      meta: {
        isUCenter: true,
        title: 'contract'
      }
    },
    {
      path: 'house',
      component: () =>
        import(/* webpackChunkName: "user-house" */ '@/views/user/house/index'),
      redirect: 'index',
      meta: {
        isUCenter: true,
        title: 'house'
      },
      children: [
        {
          path: 'query',
          component: () =>
            import(
              /* webpackChunkName: "user-house-query" */ '@/views/user/house/query'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseQuery'
          }
        },
        {
          path: 'check',
          name: 'userHouseCheck',
          component: () =>
            import(
              /* webpackChunkName: "user-house-check" */ '@/views/user/house/check'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseCheck'
          }
        },
        {
          path: 'handle',
          component: () =>
            import(
              /* webpackChunkName: "user-house-handle" */ '@/views/user/house/handle'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseHandle'
          }
        },
        {
          path: 'result',
          component: () =>
            import(
              /* webpackChunkName: "user-house-result" */ '@/views/user/house/result'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseResult'
          }
        },
        {
          path: 'index',
          component: () =>
            import(
              /* webpackChunkName: "user-house-list" */ '@/views/user/house/list'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseList'
          }
        },
        {
          path: 'realese',
          component: () =>
            import(
              /* webpackChunkName: "user-house-realese" */ '@/views/user/house/realese'
              ),
          meta: {
            isUCenter: true,
            title: 'userHouseRealese'
          }
        },
        {
          path: 'splitStatus',
          component: () =>
            import(
              /* webpackChunkName: "user-house-realese" */ '@/views/user/split/status-list'
              ),
          meta: {
            isUCenter: true,
            title: 'splitStatus'
          }
        }
      ]
    },
    {
      path: 'location',
      component: () =>
        import(
          /* webpackChunkName: "user-location" */ '@/views/user/location/index'
          ),
      meta: {
        isUCenter: true,
        title: 'location',
        icon: ''
      }
    },
    {
      path: 'relet',
      component: () =>
        import(/* webpackChunkName: "user-relet" */ '@/views/user/relet/index'),
      meta: {
        isUCenter: true,
        title: 'relet',
        icon: ''
      }
    },
    {
      path: 'deposit',
      component: () =>
        import(
          /* webpackChunkName: "user-deposit" */ '@/views/user/deposit/index'
          ),
      meta: {
        isUCenter: true,
        title: 'deposit',
        icon: ''
      }
    },
    {
      path: 'rent',
      component: () =>
        import(/* webpackChunkName: "user-rent" */ '@/views/user/rent/index'),
      meta: {
        isUCenter: true,
        title: 'rent',
        icon: ''
      }
    },
    {
      path: 'record',
      component: () =>
        import(
          /* webpackChunkName: "user-recond" */ '@/views/user/record/index'
          ),
      meta: {
        isUCenter: true,
        title: 'record',
        icon: ''
      }
    },
    {
      path: 'complaint',
      component: () =>
        import(
          /* webpackChunkName: "user-complaint" */ '@/views/user/complaint/index'
          ),
      meta: {
        isUCenter: true,
        title: 'complaint',
        icon: ''
      }
    },
    {
      path: 'appointComment',
      component: () =>
        import(
          /* webpackChunkName: "user-appoint-comment" */ '@/views/user/appointCommentList/index'
          ),
      meta: {
        isUCenter: true,
        title: 'appointComment',
        icon: ''
      }
    },
    {
      path: 'appointLandlordList',
      component: () =>
        import(
          /* webpackChunkName: "user-appoint-Landlord" */ '@/views/user/appointLandlordList/index'
          ),
      meta: {
        isUCenter: true,
        title: 'appointLandlordList',
        icon: ''
      }
    },
    {
      path: 'split',
      component: () =>
        import(/* webpackChunkName: "user-split" */ '@/views/user/split/index'),
      meta: {
        isUCenter: true,
        title: 'userHouseSplit'
      }
    },
    {
      path: 'bind',
      component: () =>
        import(/* webpackChunkName: "user-bind" */ '@/views/user/bind/index'),
      meta: {
        isUCenter: true,
        title: 'userBind'
      }
    }
  ]
}
