import request from '@/utils/request'

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/sys/login',
    method: 'POST',
    data
  })
}

/**
 * 上传
 */
export const uploadFile = (data) => {
  return request({
    url: '/file/upload',
    method: 'POST',
    data
  })
}

/**
 * 小区列表
 */
export const getCommunityList = (data) => {
  return request({
    url: '/free/communityList',
    method: 'POST',
    data
  })
}

/**
 * 小区列表
 */
export const getPlatSource = (data) => {
  return request({
    url: '/free/getPlatSource',
    params: data
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/sys/profile'
  })
}

/**
 * 获取用户信息
 */
export const queryByAccount = (data) => {
  return request({
    url: '/sys/user/queryByAccount',
    params: data
  })
}

/**
 * 查询公共参数
 * @param data
 * @returns {*}
 */
export const getAppInfoApi = () => {
  return request({
    url: '/sys/common/getAppInfo',
    method: 'GET'
  })
}

/**
 * 上传
 */
export const changePwd = (data) => {
  return request({
    url: '/sys/user/changePwd',
    method: 'POST',
    data
  })
}
