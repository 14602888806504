import request from '@/utils/request'

export const feature = () => {
  return request({
    url: '/user/feature'
  })
}

export const chapter = () => {
  return request({
    url: '/user/chapter'
  })
}

/**
 * 会员注册
 */
export const registerApi = (data) => {
  return request({
    url: '/sys/user/rentRegister',
    method: 'POST',
    data
  })
}
/**
 * 获取短信验证码
 */
export const getSmsApi = (data) => {
  return request({
    url: '/free/GetSms',
    method: 'POST',
    data
  })
}
/**
 * 修改密码/忘记密码（免登录）
 */
export const changePwdApi = (data) => {
  return request({
    url: '/sys/user/changePwd',
    method: 'POST',
    data
  })
}
/**
 * 获取用户信息
 * @param data
 * @returns {*}
 */
export const getUserInfoApi = (data) => {
  return request({
    url: '/sys/user/getUserInfo',
    method: 'GET',
    data
  })
}
