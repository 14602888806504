<template>
  <div class="footer-wrapper">
    <div>
      温馨提醒：为保障系统功能正常运行，推荐使用谷歌（Google
      Chrome）及谷歌内核浏览器。
    </div>
    <div class="own">
      <el-link
        type="primary"
        href="http://zjj.nanning.gov.cn/"
        :underline="false"
        style="font-size: 12px; margin-right: 5px"
        target="_blank"
        >南宁市住房和城乡建设局</el-link
      >
      版权所有
    </div>
    <div>
      <!-- <span style="margin-right: 10px">联系电话：0771-5603866</span> -->
      <span style="margin-right: 10px">QQ技术咨询群：920143997</span>
      <el-link
        style="margin-right: 10px; font-size: 12px"
        type="primary"
        :underline="false"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        >桂ICP备13001888号-3</el-link
      >
      <el-link
        style="font-size: 12px"
        type="primary"
        :underline="false"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010302002797"
        target="_blank"
        >桂公网安备 45010302002797号</el-link
      >
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  border-top: 1px solid #f8fafc;
  background-color: #f8fafc;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  padding: 8px 0;
  box-shadow: 0 0 20px #e6e6e6;
  color: #999;
  position: relative;
  .own {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
