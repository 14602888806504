import { getUserInfo } from '@/api/sys'
import { getUserInfoApi } from '@/api/user'

// import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN, USER_INFO, IS_TENANT, USER_MENU } from '@/constant'
import router, { resetRouter } from '@/router'
// import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: getItem(USER_INFO) || {},
    isEnterprise: false, // 是否企业用户，默认 false
    isTenant: getItem(IS_TENANT) || false, // 是否租客，默认为 true,
    userMenu: getItem(USER_MENU) || '/user/house/index' // 用户中心菜单，解决点击，或者刷新时菜单匹配准确
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      setItem(USER_INFO, userInfo)
    },
    setEnterprise(state, isEnterprise) {
      state.isEnterprise = isEnterprise
    },
    setTenant(state, isTenant) {
      state.isTenant = isTenant
      setItem(IS_TENANT, isTenant)
    },
    setUserMenu(state, userMenu) {
      state.userMenu = userMenu
      setItem(USER_MENU, userMenu)
    }
  },
  actions: {
    // login(context, userInfo) {
    //   const { username, password } = userInfo
    //   return new Promise((resolve, reject) => {
    //     login({
    //       username,
    //       password: md5(password)
    //     })
    //       .then(data => {
    //         this.commit('user/setToken', data.token)
    //         // 保存登录时间
    //         setTimeStamp()
    //         resolve()
    //       })
    //       .catch(err => {
    //         reject(err)
    //       })
    //   })
    // },
    async getUserInfo(context) {
      const res = await getUserInfoApi()
      if (res.userInfo) {
        this.commit('user/setUserInfo', res.userInfo)
      }
      return res
    },
    logout() {
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      this.commit('user/setUserMenu', '/user/info')
      this.commit('user/setTenant', true)
      removeAllItem()
      router.push('/login')
    }
  }
}
