<template>
  <!-- <el-affix> -->
  <div class="header-wrapper">
    <div class="top-wrapper">
      <div class="top-content">
        <div class="third-wrapper">
          <div class="link-item" @click="rewardFun">
            财政专项补助资金申报入口
          </div>
        </div>
        <div class="link-wrapper">
          <div class="link-item">
            <el-dropdown @command="handleCommand">
              <span
                class="dropdown-title"
                style="font-weight: 800; font-size: 16px; color: #000"
                >帮助和问题</span
              >
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="controlHelp"
                    >操作指南
                  </el-dropdown-item>
                  <el-dropdown-item command="FAQ">常见问题</el-dropdown-item>
                  <el-dropdown-item command="Download"
                    >文件下载
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-popover placement="bottom" :width="150" trigger="hover">
            <template #reference>
              <div class="link-item">微信公众号</div>
            </template>
            <el-image
              style="width: 150px; heigth: 150px"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/gongzhonghao.jpg"
            ></el-image>
          </el-popover>

          <div class="link-item" @click="goEnt">企业端后台</div>
          <div
            class="link-item"
            @click="goAuth"
            v-if="user.govRole && user.govRole === 1"
          >
            政府端后台
          </div>
          <div
            class="link-item"
            @click="goProperty"
            v-if="user.propertyRole && user.propertyRole === 1"
          >
            物业管理
          </div>
          <!-- <div class="link-item" @click="goEntRegister">企业注册1</div>
          <a
            class="link-item"
            style="display: block"
            href="https://tr.nnfcxx.com/"
            target="_blank"
            >企业注册</a
          > -->
          <div class="link-item" @click="goBind">重新绑定</div>
        </div>
        <div class="login-wrapper">
          <el-popover placement="bottom" trigger="hover">
            <template #reference>
              <div class="login-container">
                <div
                  style="
                    background: #a0cfff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                  "
                >
                  <el-icon :size="size" color="#ffffff" v-if="!isLogin">
                    <Avatar />
                  </el-icon>
                  <el-image
                    style="width: 18px; height: 18px; border-radius: 50%"
                    :src="userAvatar"
                    v-else
                  >
                    <template #error>
                      <el-image
                        class="image"
                        src="http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586"
                      >
                      </el-image>
                    </template>
                  </el-image>
                </div>
                <div style="margin: 0 5px" v-if="!isLogin">未登录</div>
                <div style="margin: 0 5px" v-else>{{ user.realname }}</div>
                <el-icon :size="size">
                  <ArrowDown />
                </el-icon>
              </div>
            </template>
            <div class="logout-wrapper" v-if="!isLogin">
              <div class="logout-item" @click="login">登录</div>
              <div class="logout-item" @click="register">注册</div>
            </div>
            <div class="logout-wrapper" v-else>
              <div class="logout-item" @click="goUserCenter">个人中心</div>
              <div class="logout-item" @click="safeLogout">安全退出</div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="logo-menu-wrapper">
      <div class="logo-wrapper">
        <div class="logo-image">
          <el-image
            style="width: 52px; height: 52px"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/logo.png"
          />
          <span class="logo-sise">南宁市住房租赁服务监管平台</span>
        </div>
        <div class="search-wrapper">
          <!--          <el-input-->
          <!--            v-model='input2'-->
          <!--            placeholder='请输入小区关键字'-->
          <!--          />-->
          <el-select
            v-model="searchValue"
            filterable
            remote
            reserve-keyword
            style="width: 300px; margin-right: 5px"
            placeholder="请输入小区关键字"
            :remote-method="fetchCommunityList"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.id + ``"
              :label="item.name"
              :value="item.name"
            />
          </el-select>

          <el-button type="primary" @click="toSearch">搜索</el-button>
          <el-button type="primary" plain @click="mapSearch"
            >地图找房
          </el-button>
        </div>
      </div>
    </div>
    <div class="menu-wrapper">
      <div class="menu-content">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#409eff"
          text-color="#ffffff"
          active-text-color="#ffffff"
          :router="true"
        >
          <el-menu-item index="/home">平台首页</el-menu-item>
          <el-sub-menu index="2">
            <template #title>我要出租</template>
            <el-menu-item index="/user/house/query">自主挂牌</el-menu-item>
            <el-menu-item index="/user/house/index">房源上架</el-menu-item>
          </el-sub-menu>

          <el-menu-item index="/house/list">我要租房</el-menu-item>
          <el-menu-item index="/user/contract">网签备案</el-menu-item>
          <el-menu-item index="/house/check">房源验真</el-menu-item>
          <el-menu-item index="/enterpriseInfo/list">企业信息</el-menu-item>
          <el-menu-item index="/notice">公示公告</el-menu-item>
          <el-menu-item index="/creditEvaluate">信用评价</el-menu-item>
          <el-menu-item index="/supportServer">配套服务</el-menu-item>
          <el-menu-item index="/info">行业资讯</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
  <!-- </el-affix> -->
</template>

<script setup>
import { ref, computed } from '@vue/reactivity'
import { removeAllItem } from '@/utils/storage'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { entLogin, logout, propertyLogin, entManageLogin } from '@/api/login'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getCommunityList } from '@/api/sys'

onMounted(() => {
  // fetchCommunityList()
})
const loading = ref(false)
const router = useRouter()
const store = useStore()
const searchValue = ref('')
const size = ref(16)
const activeIndex = ref('1')

const isLogin = computed(() => {
  return !!store.getters.token
})

const user = computed(() => {
  return store.getters.userInfo
})
const userAvatar = computed(() => store.getters.userAvatar)

const token = computed(() => store.getters.token)
const companyAuth = computed(() => store.getters.companyAuth)
const userInfo = computed(() => store.state.user.userInfo)

const goEnt = async () => {
  if (!token.value) {
    ElMessage.error('账号未登录或者已超时，请重新登录！') // 提示错误信息
    return
  }
  const resp = await entLogin()
  if (resp && resp.code === 200 && Array.isArray(resp.result)) {
    router.push('/user/bind')
  } else if (
    resp &&
    resp.code === 200 &&
    resp.message &&
    resp.message === '企业登录成功!'
  ) {
    const a = document.createElement('a')
    // a.target = '_blank'
    // type类型 1 企业端 2 政府端
    a.href =
      process.env.VUE_APP_BASE_URL_BUZZ +
      '/transfer?type=1&token=' +
      token.value +
      '&property=2'
    a.click()
  } else {
    ElMessage.error(resp.message)
  }
}

const goAuth = async () => {
  if (!token.value) {
    ElMessage.error('账号未登录或者已超时，请重新登录！') // 提示错误信息
    return
  }

  // 判断
  const a = document.createElement('a')
  // a.target = '_blank'
  // type类型 1 企业端 2 政府端
  a.href =
    process.env.VUE_APP_BASE_URL_BUZZ + '/transfer?type=2&token=' + token.value
  a.click()
}

// 企业注册
const goEntRegister = async () => {
  const resp = await entManageLogin()
  if (resp && resp.code === 200) {
    const a = document.createElement('a')
    a.href =
      process.env.VUE_APP_BASE_URL_BUZZ +
      '/transfer?type=3&token=' +
      token.value
    a.click()
  } else {
    ElMessage.error(resp.message)
  }
}

const goProperty = async () => {
  if (!token.value) {
    ElMessage.error('账号未登录或者已超时，请重新登录！') // 提示错误信息
    return
  }
  const resp = await propertyLogin()
  if (resp && resp.code === 200) {
    const a = document.createElement('a')
    // a.target = '_blank'
    // type类型 1 企业端 2 政府端
    a.href =
      process.env.VUE_APP_BASE_URL_BUZZ +
      '/transfer?type=1&token=' +
      token.value +
      '&property=1'
    a.click()
  } else {
    ElMessage.error(resp.message)
  }
}

const goBind = async () => {
  if (!token.value) {
    ElMessage.error('账号未登录或者已超时，请重新登录！') // 提示错误信息
    return
  }
  router.push('/user/bind')
}
const goUserCenter = () => {
  router.push('/user/house/index')
}
const safeLogout = async () => {
  const resq = await logout()
  ElMessage.success('退出成功！')
  store.dispatch('user/logout')
}
const login = () => {
  router.push('/login')
}
const register = () => {
  router.push('/register')
}
const handleSelect = () => {
  console.log('handleSelect')
}
const mapSearch = () => {
  const routeUrl = router.resolve({
    name: 'mapSearch'
  })
  window.open(routeUrl.href, '_blank')
}
// 奖补跳转
const rewardFun = () => {
  // if (!token.value) {
  //   ElMessage.error('账号未登录或者已超时，请重新登录！') // 提示错误信息
  //   router.push('/user')
  //   return
  // }
  if (companyAuth.value && companyAuth.value !== '0') {
    window.open(
      process.env.VUE_APP_DOMAIN_JB + '?token=' + token.value,
      '_blank'
    )
  } else {
    ElMessageBox.confirm('访问中央财政资金奖补系统需要绑定企业？', '温馨提醒', {
      confirmButtonText: '去绑定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      // 跳转到实名认证
      router.push('/user/bind')
    })
  }
}
/**
 * 下拉菜单方法
 */
const handleCommand = (e) => {
  switch (e) {
    case 'controlHelp':
      router.push('/FAQ/help')
      break
    case 'FAQ':
      router.push('/FAQ')
      break
    case 'Download':
      router.push('/FAQ/download')
      break
  }
}

const options = ref([])
// 获取小区信息
const fetchCommunityList = (query) => {
  const queryData = async () => {
    try {
      loading.value = true
      const result = await getCommunityList({ communityName: query })
      options.value = result
    } finally {
      loading.value = false
    }
  }
  // 如果数据正在搜素则延长时间加倍
  const time = loading.value ? 1000 * 2 : 1000
  setTimeout(queryData, time)
}

const toSearch = () => {
  router.push({
    name: 'houseList',
    params: { projectName: searchValue.value }
  })
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: #ffffff;

  .top-wrapper {
    border-bottom: 1px solid #e5e5e5;

    .top-content {
      width: 1280px;
      font-weight: 800;
      display: flex;
      align-items: center;
      height: 42px;
      margin: 0 auto;

      .third-wrapper {
        width: 500px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link-item {
          cursor: pointer;

          &:hover {
            color: #409eff;
          }
        }
      }
    }

    .link-wrapper {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .link-item {
        cursor: pointer;

        &:hover {
          color: #409eff;
        }
      }
    }

    .login-wrapper {
      width: 150px;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .logo-menu-wrapper {
    width: 1280px;
    margin: 0 auto;

    .logo-wrapper {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo-image {
        display: flex;
        align-items: center;

        .logo-sise {
          font-size: 30px;
          margin-left: 10px;
          color: #333;
        }
      }

      .search-wrapper {
        display: flex;
      }
    }
  }

  .menu-wrapper {
    background-color: #409eff;
    height: 60px;

    .menu-content {
      width: 1280px;
      margin: 0 auto;
    }
  }
}

.el-menu-item {
  min-width: 120px;
}

.el-sub-menu {
  min-width: 120px;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
}

.logout-wrapper {
  padding: 0 5px;
  text-align: center;
  cursor: pointer;

  .logout-item {
    padding: 10px 0;
    cursor: pointer;

    &:hover {
      color: #409eff;
      background-color: #ecf5ff;
    }
  }
}
</style>
