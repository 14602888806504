import router from './router'
import store from './store'
import { ElMessageBox } from 'element-plus'
import { computed } from 'vue'

// 白名单
const whiteList = [
  '/loginByCas',
  '/login',
  '/home',
  '/notice',
  '/house/list',
  '/house/detail',
  '/enterpriseInfo/list',
  '/enterpriseInfo/detail',
  '/rent',
  '/info',
  '/creditEvaluate', // 企业红黑榜
  '/register',
  '/apartment/detail', // 集中式公寓项目详情
  '/apartment/houseDetail', // 集中式公寓房源详情
  '/forgetThePassword', // 忘记密码
  '/supportServer',
  '/house/check',
  '/mapSearch',
  '/notice',
  '/FAQ',
  '/FAQ/help',
  '/FAQ/download',
  '/article',
  '/auth',
  '/cert',
  '/changePwd',
  '/logout',
  '/trans'
]
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // 快捷访问
  if (store.getters.token) {
    if (to.path === '/login') {
      // next('/')
      next()
    } else {
      const userInfo = computed(() => store.getters.userInfo)
      // 判断用户资料是否获取
      // 若不存在用户信息，则需要获取用户信息
      // if (!store.getters.hasUserInfo) {
      //   // 触发获取用户信息的 action，并获取用户当前权限
      //   const { permission } = await store.dispatch('user/getUserInfo')
      //   // 处理用户权限，筛选出需要添加的权限
      //   const filterRoutes = await store.dispatch(
      //     'permission/filterRoutes',
      //     permission.menus
      //   )
      //   console.log(filterRoutes)
      //   // 利用 addRoute 循环添加
      //   filterRoutes.forEach(item => {
      //     router.addRoute(item)
      //   })
      //   // 添加完动态路由之后，需要在进行一次主动跳转
      //   return next(to.path)
      // }
      if (to.meta.isUCenter) {
        const pathArr = to.fullPath.split('/')
        let newPath = ''
        if (pathArr.length > 3) {
          newPath = pathArr.slice(0, 3).join('/') + '/index'
        } else {
          newPath = to.fullPath
        }
        store.commit('user/setUserMenu', newPath)
      }

      // 未实名，需重置密码，需上传身份证正反面
      // 实名认证状态 0:未认证 1:已认证
      if (whiteList.indexOf(to.path) === -1) {
        if (userInfo.value.faceAuth !== 1) {
          await ElMessageBox.confirm(
            '当前账号未实名认证，是否前往认证？',
            '温馨提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              // 跳转到实名认证
              next('/auth')
            })
            .catch(() => {})
        } else if (userInfo.value.resetPwd !== 2) {
          // 是否需要强行重置密码的用户1 需要强行重置 2 不需要
          await ElMessageBox.confirm(
            '当前账号需要强行重置密码，是否前往重置密码？',
            '温馨提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              // 跳转到实名认证
              next('/changePwd')
            })
            .catch(() => {})
        } else if (userInfo.value.source === 2) {
          // 平台来源用户信息来源 0:租赁平台注册,1:选房网带身份证,2:选房网不带身份证
          await ElMessageBox.confirm(
            '当前账号需要上传身份证正反面，是否前往？',
            '温馨提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              // 跳转到实名认证
              next('/cert')
            })
            .catch(() => {})
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else if (to.meta.isProps) {
      next()
    } else {
      next('/login')
    }
  }
})
