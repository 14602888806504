import { setItem, getItem } from '@/utils/storage'
import { WARRANT_DATA } from '@/constant'

export default {
  namespaced: true,
  state: () => ({
    warrantData: getItem(WARRANT_DATA) || ''
  }),
  mutations: {
    setWarrantData(state, data) {
      state.warrantData = data
      setItem(WARRANT_DATA, data)
    }
  },
  actions: {}
}
