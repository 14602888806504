<template>
  <div class="sidebar-wrapper">
    <div class="sidebar-item">
      <div
        class="sidebar-image qq-gray"
        style="height: 45px; width: 50px"
      ></div>
      <div class="sidebar-text">QQ咨询群 920143997</div>
      <div class="qqhao"></div>
    </div>
    <div class="sidebar-item">
      <div
        class="sidebar-image phone-gray"
        style="height: 45px; width: 50px"
      ></div>
      <div class="sidebar-text">业务咨询</div>
      <div
        class="download-mock"
        style="
          background-color: #268eff;
          height: 170px;
          width: 200px;
          left: -210px;
        "
      >
        <div>业务咨询: 0771-5656948</div>
        <div style="margin-top: -30px">咨询服务时间:(法定工作日)</div>
        <div style="margin-top: -30px">上午9:00-12:00</div>
        <div style="margin-top: -30px">下午13:30-17:30</div>
      </div>
    </div>

    <div class="sidebar-item">
      <div class="sidebar-image download-gray"></div>
      <div class="sidebar-text">公众号</div>
      <div class="gongzhonghao"></div>
    </div>

    <div class="sidebar-item">
      <div class="sidebar-image hetong-gray"></div>
      <div class="sidebar-text">合同模板</div>
      <a
        href="https://pc.nnfwzl.com/rent-house/rent-platform-web/%e5%8d%97%e5%ae%81%e5%b8%82%e6%88%bf%e5%b1%8b%e7%a7%9f%e8%b5%81%e5%90%88%e5%90%8c%ef%bc%88%e7%a4%ba%e8%8c%83%e6%96%87%e6%9c%ac%ef%bc%89.doc"
        class="download-mock"
        >下载合同模板</a
      >
    </div>
    <a class="sidebar-item" @click="scrollToTop">
      <div class="sidebar-image top-gray"></div>
      <div class="sidebar-text">返回顶部</div>
    </a>
  </div>
  <div class="sidebar-kf" @click="openKf()">
    <img
      src="https://pc.nnfwzl.com/rent-house/rent-platform-web/2022_09_19kf.png"
    />
  </div>
  <div class="sidebar-wrapper fixed-left">
    <div class="sidebar-item" @click="openLink('fundSupervisionFun')">
      <div
        class="sidebar-image system-gray"
        style="height: 40px; width: 40px"
      ></div>
      <div class="sidebar-text">租赁资金监管入口</div>
    </div>
    <div class="sidebar-item" @click="openLink('rewardFun')">
      <div
        class="sidebar-image system-gray"
        style="height: 40px; width: 40px"
      ></div>
      <div class="sidebar-text">财政专项补助资金申报入口</div>
    </div>
    <div class="sidebar-item" @click="goSecondHead">
      <div
        class="sidebar-image system-gray"
        style="height: 40px; width: 40px"
      ></div>
      <div class="sidebar-text">南宁市住房服务监管平台</div>
    </div>
    <div class="sidebar-item" @click="openLink('bzfFun')">
      <div
        class="sidebar-image system-gray"
        style="height: 40px; width: 40px"
      ></div>
      <div class="sidebar-text">南宁市保租房系统</div>
    </div>

    <div class="tip-blue-top tip-ng">
      <img src="../../assets/laba.png" style="width: 24px; height: 24px" />
      温馨提示：为了保障您的合法权益，在签订房屋租赁合同时，建议您使用
      <a :href="downUrl">《南宁市房屋租赁合同示范文本》</a> 。下载请点击：<a
        :href="downUrl"
        >《示范文本》</a
      >
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { nextTick } from 'vue'

export default {
  name: 'RightFloat',
  emits: ['scrollTop'],
  data() {
    return {
      timer: null,
      top: 0,
      downUrl:
        process.env.VUE_APP_BASE_HELP_DOWN_HOST +
        '/rent-house/notice/《南宁市房屋租赁合同示范文本》(2019版) .doc'
    }
  },
  mounted() {
    window.addEventListener('scroll', (ev) => {
      console.log(ev)
    })
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    companyAuth() {
      return this.$store.getters.companyAuth
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  methods: {
    openKf() {
      window.open(process.env.VUE_APP_DOMAIN_KF, '_blank')
    },
    // 租赁资金监管跳转
    fundSupervisionFun() {
      window.open(
        process.env.VUE_APP_DOMAIN_ZLZJJG + '?token=' + this.token,
        '_blank'
      )
    },
    // 财政专项补助资金申报入口
    rewardFun() {
      if (this.companyAuth && this.companyAuth !== '0') {
        window.open(
          process.env.VUE_APP_DOMAIN_JB + '?token=' + this.token,
          '_blank'
        )
      } else {
        this.goBindCompany()
      }
    },
    goBindCompany() {
      ElMessageBox.confirm(
        '访问中央财政资金奖补系统需要绑定企业？',
        '温馨提醒',
        {
          confirmButtonText: '去绑定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        // 跳转到实名认证
        this.$router.push('/user/bind')
      })
    },
    // 南宁市住房服务监管平台
    goSecondHead() {
      const domain = process.env.VUE_APP_DOMAIN_CLF
      if (!this.token) {
        window.open(`${domain}secondHead/list`, '_blank')
        return
      }
      // 平台来源用户信息来源 0:租赁平台注册,1:选房网带身份证,2:选房网不带身份证
      if (this.userInfo.source === 2) {
        ElMessageBox.confirm('需要先补充身份证再办理业务', '温馨提醒', {
          confirmButtonText: '去补充',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // TODO 功能页面未实现，跳转到实名认证，完成功能后再打开
          this.$router.push('/user')
        })
      }
      window.open(`${domain}main?token=${this.token}`, '_blank')
    },
    // 南宁市保租房系统
    bzfFun() {
      if (this.companyAuth && this.companyAuth !== '0') {
        window.open(
          process.env.VUE_APP_URL_DOMAIN_BZF + '?token=' + this.token,
          '_blank'
        )
      } else {
        this.goBindCompany()
      }
    },

    openLink(fucName) {
      // if (!this.token) {
      //   ElMessage.error('当前未登录，请先登录')
      //   this.$router.push('/login')
      //   return
      // }
      // eslint-disable-next-line no-eval
      eval(`this.${fucName}()`)
    },

    scrollToTop(e) {
      this.$emit('scrollTop')
    }
  }
}
</script>

<style scoped>
.tip-ng {
  display: none;
}

@media screen and (min-width: 1400px) {
  .tip-ng {
    display: block;
  }
}

.sidebar-wrapper {
  width: 70px;
  border: solid 1px #eee;
  position: fixed;
  right: 20px;
  top: 20%;
  /*background: #fff;*/
  z-index: 9999;
}

.fixed-left {
  left: 20px;
}

.sidebar-wrapper .sidebar-item {
  position: relative;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: block;
  background: #268eff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 3px 0;
  color: #fff;
  padding: 0 8px 3px;
}

.sidebar-wrapper .sidebar-item:hover {
  color: #000;
  background-color: #fff;
}

.sidebar-wrapper .sidebar-item .sidebar-image {
  height: 32px;
  width: 32px;
}

.sidebar-wrapper .sidebar-item .sidebar-text {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.sidebar-wrapper .sidebar-item .gongzhonghao {
  background: url(https://pc.nnfwzl.com/rent-house/rent-platform-web/gongzhonghao.jpg)
    no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100%;

  border: solid 1px #268eff;
  border-radius: 10px;
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0px;
  left: -155px;
  display: none;
}

.sidebar-wrapper .sidebar-item .qqhao {
  background: url(https://pc.nnfwzl.com/rent-house/rent-platform-web/QQqun.png)
    no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100%;
  border: solid 1px #268eff;
  border-radius: 10px;
  height: 350px;
  width: 250px;
  position: absolute;
  top: 0px;
  left: -255px;
  display: none;
}

.sidebar-wrapper .sidebar-item .download-mock {
  width: 100px;
  height: 100%;
  display: none;
  position: absolute;
  top: 0px;
  left: -100px;
  background-color: #999;
  color: #fff;
  font-size: 14px;
  line-height: 65px;
  text-align: center;
}

.sidebar-wrapper .sidebar-item:hover .download-mock {
  display: block;
}

.sidebar-wrapper .sidebar-item:hover .gongzhonghao {
  display: block;
}

.sidebar-wrapper .sidebar-item:hover .qqhao {
  display: block;
}

.sidebar-wrapper .sidebar-item:hover .phone-gray {
  background: url(../../assets/float/phone1.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .phone-gray {
  background: url(../../assets/float/phone1.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .system-gray {
  background: url(../../assets/float/system-project.png) no-repeat;
  background-size: 100%;
}

.sidebar-wrapper .sidebar-item:hover .system-gray {
  background: url(../../assets/float/system-project-gray.png) no-repeat;
  background-size: 100%;
}

.sidebar-wrapper .sidebar-item:hover .qq-gray {
  background: url(../../assets/float/QQ.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .qq-gray {
  background: url(../../assets/float/QQ.png) no-repeat;
}

.sidebar-wrapper .sidebar-item:hover .download-gray {
  background: url(../../assets/float/down-load-gray.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .download-gray {
  background: url(../../assets/float/down-load-light.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .kefu-gray {
  background: url(../../assets/float/kefu-gray.png) no-repeat;
}

.sidebar-wrapper .sidebar-item:hover .kefu-gray {
  background: url(../../assets/float/kefu-light.png) no-repeat;
}

.sidebar-wrapper .sidebar-item:hover .hetong-gray {
  background: url(../../assets/float/hetong-gray.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .hetong-gray {
  background: url(../../assets/float/hetong-light.png) no-repeat;
}

.sidebar-wrapper .sidebar-item:hover .top-gray {
  background: url(../../assets/float/go-top-gray.png) no-repeat;
}

.sidebar-wrapper .sidebar-item .top-gray {
  background: url(../../assets/float/go-top-light.png) no-repeat;
}

.hoverpointer:hover {
  cursor: pointer;
}

.link-item {
  padding: 20px 10px;
  width: 28%;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  background: #268eff;
  font-size: 16px;
  color: white;
}

.sidebar-kf {
  position: fixed;
  right: 10px;
  bottom: 100px;
  width: 150px;
  height: 150px;
  z-index: 2000;
  cursor: pointer;
  box-shadow: -4px 8px 9px 4px #e5e5e5;
}

.tip-blue-top {
  width: 150px;
  background-color: #268eff;
  margin-top: 36px;
  font-size: 14px;
  color: #fff;
  padding: 6px;
  line-height: 1.6;
  border-radius: 6px;
}
</style>
