import axios from 'axios'
import store from '@/store'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
// import { isCheckTimeout } from '@/utils/auth'

const router = useRouter()

const NOT_HANDLE_RESULT_LIST = [
  '/enterprise/login',
  '/release/getOwnHouseInfo',
  '/sys/rentLogin',
  '/free/GetSms',
  '/sys/logout',
  '/esigne/checkCardImg',
  '/esigne/uploadCardImg',
  '/enterprise/loginProperty',
  '/enterprise/loginEnterprise'
]

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在这个位置需要统一的去注入token
    // if (store.getters.token) {
    //   if (isCheckTimeout()) {
    //     // 登出操作
    //     store.dispatch('user/logout')
    //     return Promise.reject(new Error('token 失效'))
    //   }
    //   // 如果token存在 注入token
    //   // config.headers.Authorization = `Bearer ${store.getters.token}`
    //   config.headers['X-Access-Token'] = store.getters.token
    // }
    config.headers['X-Access-Token'] = store.getters.token
    // 配置接口国际化
    config.headers['Accept-Language'] = store.getters.language

    return config // 必须返回配置
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { code, message, result } = response.data
    //   要根据success的成功与否决定下面的操作
    if (NOT_HANDLE_RESULT_LIST.includes(response.config.url)) {
      return response.data
    } else if (code === 200 || code === 0) {
      return result
    } else {
      // 业务错误
      ElMessage.error(message) // 提示错误消息
      // return { code, message }
      return Promise.reject(new Error(message))
    }
  },
  async (error) => {
    // 处理 token 超时问题
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      // token超时
      ElMessage.error('账号已超时，请重新登录！') // 提示错误信息
      store.dispatch('user/logout')
    } else if (
      error.response &&
      error.response.data &&
      [601, 602, 603].includes(error.response.data.code)
    ) {
      const errorOption = {
        601: { message: '当前账号未实名认证，是否前往认证？', path: '/auth' },
        603: {
          message: '当前账号需要强行重置密码，是否前往重置密码？',
          path: '/changePwd'
        },
        602: {
          message: '当前账号需要上传身份证正反面，是否前往？',
          path: '/cert'
        }
      }
      await ElMessageBox.confirm(
        errorOption[error.response.data.code].message,
        '温馨提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          router.push(errorOption[error.response.data.code].path)
        })
        .catch(() => {})
    } else {
      ElMessage.error(error.message) // 提示错误信息
    }
    return Promise.reject(error)
  }
)

export default service
