<template>
  <div class='app-wrapper'>
    <el-scrollbar ref='scrollbarRef'>
      <!-- 公共头部 -->
      <Header />
      <!-- 内容区 -->
      <AppMain />
      <!-- 公共底部 -->
      <Footer />
    </el-scrollbar>
    <div v-show='showFloat'>
      <HomeFloat @scrollTop='srollTopHandel'></HomeFloat>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import Header from './components/Header'
import Footer from './components/Footer'
import AppMain from './components/AppMain'
import HomeFloat from '@/components/HomeFloat'

const route = useRoute()
const showFloat = computed(() => {
  return route.path === '/home'
})
const scrollbarRef = ref(null)

function srollTopHandel() {
  scrollbarRef.value.setScrollTop(0)
}
</script>

<style lang='scss' scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width #{$sideBarDuration};
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
