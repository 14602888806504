// token
export const TOKEN = 'token'
// userInfo
export const USER_INFO = 'userInfo'
// 房源权证号
export const WARRANT_DATA = 'warrantData'
// 是否租客角色，true：租客，fasle：房东
export const IS_TENANT = 'isTenant'
// 个人中心菜单，通过路由钩子变换
export const USER_MENU = 'user_menu'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'
// tags
export const TAGS_VIEW = 'tagsView'

// 南宁市城区
export const AREA_MAP = [
  { value: '0', label: '不限' },
  { value: '450102', label: '兴宁区' },
  { value: '450103', label: '青秀区' },
  { value: '450105', label: '江南区' },
  { value: '450107', label: '西乡塘区' },
  { value: '450108', label: '良庆区' },
  { value: '450109', label: '邕宁区' },
  { value: '450110', label: '武鸣区' }
]

// 发房来源
export const SOURCE_MAP = [
  { value: '0', label: '不限' },
  { value: '贝壳网', label: '贝壳网' },
  { value: '房天下', label: '房天下' },
  { value: '住朋网', label: '住朋网' },
  { value: 'CCB建融家园', label: 'CCB建融家园' },
  // { value: '房无忧', label: '房无忧' },
  { value: '邕有家', label: '邕有家' },
  // { value: '兰庭', label: '兰庭' },
  { value: '优居', label: '优居' },
  // { value: '正和兴', label: '正和兴' },
  // { value: '58同城', label: '58同城' },
  // { value: '恒创爱家', label: '恒创爱家' }
  { value: '全房通', label: '全房通' }
  // { value: '安居客', label: '安居客' },
  // { value: '江山', label: '江山' },
  // { value: '富航', label: '富航' }
]

// 面积
export const SQUARE_MAP = [
  { value: '0', label: '不限' },
  { value: '0_50', label: '50㎡以下' },
  { value: '50_70', label: '50-70㎡' },
  { value: '70_90', label: '70-90㎡' },
  { value: '90_120', label: '90-120㎡' },
  { value: '120_150', label: '120-150㎡' },
  { value: '150_200', label: '150-200㎡' },
  { value: '200_300', label: '200-300㎡' },
  { value: '300_99999', label: '300㎡以上' }
]

// 售价
export const SALE_MAP = [
  { value: '0', label: '不限' },
  { value: '0_50', label: '50万以下' },
  { value: '50_70', label: '50-70万' },
  { value: '70_80', label: '70-80万' },
  { value: '80_90', label: '80-90万' },
  { value: '90_100', label: '90-100万' },
  { value: '100_120', label: '100-120万' },
  { value: '120_140', label: '120-140万' },
  { value: '140_200', label: '140-200万' },
  { value: '200_250', label: '200-250万' },
  { value: '250_99999', label: '250万以上' }
]

// 价格区间
export const PRICE_MAP = [
  { value: '0', label: '不限' },
  { value: '0_800', label: '800元以下' },
  { value: '800_1200', label: '800-1200元' },
  { value: '1200_1500', label: '1200-1500元' },
  { value: '1500_2000', label: '1500-2000元' },
  { value: '2000_3000', label: '2000-3000元' },
  { value: '3000_4000', label: '3000-4000元' },
  { value: '4000_5000', label: '4000-5000元' },
  { value: '5000_', label: '5000元以上' }
]

// 支付方式
export const PAYWAY_MAP = [
  { value: '3', label: '付一押一' },
  { value: '8', label: '付一押二' },
  { value: '10', label: '付一押三' },
  { value: '2', label: '付二押一' },
  { value: '1', label: '付三押一' },
  { value: '4', label: '付三押二' },
  { value: '11', label: '月付' },
  { value: '5', label: '半年付' },
  { value: '9', label: '季付' },
  { value: '6', label: '整年付' },
  { value: '7', label: '面议' }
]

// 楼层
export const FLOOR_MAP = [
  { value: '0', label: '不限' },
  { value: '6', label: '底层' },
  { value: '7_9', label: '中层' },
  { value: '10', label: '高层' }
]

export const SORT_TYPE_MAP = [
  { value: '0', label: '不限' },
  { value: '1', label: '挂牌时间从近到远' },
  { value: '2', label: '挂牌时间从远到近' },
  { value: '3', label: '房屋租金由高到低' },
  { value: '4', label: '房屋租金由低到高' }
]

// 智能排序
export const SORT_MAP = [
  { value: '0', label: '不限' },
  { value: '1', label: '智能排序' },
  { value: '2', label: '租金由低到高' },
  { value: '3', label: '最新发布' }
]

// 出租类型
export const RENT_TYPE_MAP = [
  { value: '0', label: '不限', active: true },
  { value: '1', label: '整租', active: false },
  { value: '2', label: '分租', active: false }
]

// 房源户型
export const LAYOUT_MAP = [
  { value: '0', label: '不限', active: true },
  { value: '1', label: '一室', active: false },
  { value: '2', label: '二室', active: false },
  { value: '3', label: '三室', active: false },
  { value: '4', label: '四室', active: false },
  { value: '5', label: '五室以上', active: false }
]

// 厅数
export const HALL_MAP = [
  { value: '0', label: '不限', active: true },
  { value: '1', label: '一厅', active: false },
  { value: '2', label: '二厅', active: false },
  { value: '3', label: '三厅', active: false },
  { value: '4', label: '四厅', active: false },
  { value: '5', label: '五厅以上', active: false }
]
// toiletNum
export const TOILET_MAP = [
  { value: '0', label: '不限', active: true },
  { value: '1', label: '一卫', active: false },
  { value: '2', label: '二卫', active: false },
  { value: '3', label: '三卫', active: false },
  { value: '4', label: '四卫', active: false },
  { value: '5', label: '五卫以上', active: false }
]

// 朝向
export const TOWARD_MAP = [
  { value: '0', label: '不限' },
  { value: '1', label: '东' },
  { value: '2', label: '南' },
  { value: '3', label: '西' },
  { value: '4', label: '北' },
  { value: '5', label: '东南' },
  { value: '6', label: '西南' },
  { value: '7', label: '东北' },
  { value: '8', label: '西北' },
  { value: '9', label: '南北' },
  { value: '10', label: '东西' },
  { value: '11', label: '暂无朝向' }
]

// 付款方式
export const SALE_PAY_MAP = [
  { value: '1', label: '按揭1成' },
  { value: '2', label: '按揭2成' },
  { value: '3', label: '按揭3成' },
  { value: '4', label: '按揭4成' },
  { value: '5', label: '按揭5成' },
  { value: '6', label: '按揭6成' },
  { value: '7', label: '按揭7成' },
  { value: '8', label: '按揭8成' },
  { value: '9', label: '按揭9成' },
  { value: '10', label: '全款' },
  { value: '0', label: '面议' }
]

export const USE_MAP = [
  { value: '1', label: '不限' },
  { value: '2', label: '商品房住宅' },
  { value: '3', label: '商住两用' },
  { value: '4', label: '经济适用房' },
  { value: '5', label: '公房' },
  { value: '6', label: '动迁配套房' },
  { value: '7', label: '其他' }
]

// 装修 SITUATIONS_MAP
export const SITUATIONS_MAP = [
  { value: '0', label: '不限' },
  { value: '1', label: '毛坯' },
  { value: '2', label: '普通装修' },
  { value: '3', label: '精装修' },
  { value: '4', label: '豪华装修' },
  { value: '5', label: '其他' }
]

// 合同状态 CONTRACT_MAP
export const CONTRACT_MAP = [
  { value: '1', label: '待确认' },
  { value: '2', label: '确认中' },
  { value: '3', label: '网签备案失败' },
  { value: '4', label: '合同生效' },
  { value: '5', label: '待注销' },
  { value: '6', label: '注销中' },
  { value: '7', label: '注销失败' },
  { value: '8', label: '合同失效' },
  { value: '9', label: '签约拒绝' },
  { value: '10', label: '解约拒绝' },
  { value: '11', label: '房源已网签' },
  { value: '12', label: '合同申请中' },
  { value: '13', label: '合同审核中' },
  { value: '14', label: '审核不通过' },
  { value: '15', label: '撤销申请失败' },
  { value: '16', label: '撤销合同申请' },
  { value: '17', label: '传销租赁信息采集' }
]

// 房源详情设备
export const INSTALL_MAP = [
  {
    id: 1,
    color: '#c0c4cc',
    text: '电视',
    icon: 'custom-icon-dianshi',
    isCheck: false
  },
  {
    id: 2,
    color: '#c0c4cc',
    text: '冰箱',
    icon: 'custom-icon-bingxiang',
    isCheck: false
  },
  {
    id: 3,
    color: '#c0c4cc',
    text: '洗衣机',
    icon: 'custom-icon-xiyiji',
    isCheck: false
  },
  {
    id: 4,
    color: '#c0c4cc',
    text: '空调',
    icon: 'custom-icon-kongtiao',
    isCheck: false
  },
  {
    id: 5,
    color: '#c0c4cc',
    text: '热水器',
    icon: 'custom-icon-reshuiqi',
    isCheck: false
  },
  {
    id: 6,
    color: '#c0c4cc',
    text: '床',
    icon: 'custom-icon-chuang',
    isCheck: false
  },
  {
    id: 7,
    color: '#c0c4cc',
    text: '书桌',
    icon: 'custom-icon-shuzhuo',
    isCheck: false
  },
  {
    id: 8,
    color: '#c0c4cc',
    text: '椅子',
    icon: 'custom-icon-yizi',
    isCheck: false
  },
  {
    id: 9,
    color: '#c0c4cc',
    text: '沙发',
    icon: 'custom-icon-shafa',
    isCheck: false
  },
  {
    id: 10,
    color: '#c0c4cc',
    text: '厨房',
    icon: 'custom-icon-dianfanbao',
    isCheck: false
  },
  {
    id: 11,
    color: '#c0c4cc',
    text: '宽带',
    icon: 'custom-icon-wangluo',
    isCheck: false
  },
  {
    id: 12,
    color: '#c0c4cc',
    text: '衣柜',
    icon: 'custom-icon-yigui',
    isCheck: false
  },
  {
    id: 13,
    color: '#c0c4cc',
    text: '燃气灶',
    icon: 'custom-icon-ranqi',
    isCheck: false
  },
  {
    id: 14,
    color: '#c0c4cc',
    text: '卫生间',
    icon: 'custom-icon-matong',
    isCheck: false
  },
  {
    id: 15,
    color: '#c0c4cc',
    text: '浴缸',
    icon: 'custom-icon-yugang',
    isCheck: false
  },
  {
    id: 16,
    color: '#c0c4cc',
    text: '阳台',
    icon: 'custom-icon-yangtai',
    isCheck: false
  },
  {
    id: 17,
    color: '#c0c4cc',
    text: '电梯',
    icon: 'custom-icon-dianti',
    isCheck: false
  },
  {
    id: 18,
    color: '#c0c4cc',
    text: '床头柜',
    icon: 'custom-icon-chuangtougui',
    isCheck: false
  },
  {
    id: 19,
    color: '#c0c4cc',
    text: '书架',
    icon: 'custom-icon-shujia',
    isCheck: false
  },
  {
    id: 20,
    color: '#c0c4cc',
    text: '微波炉',
    icon: 'custom-icon-weibolu',
    isCheck: false
  },
  {
    id: 21,
    color: '#c0c4cc',
    text: '烤箱',
    icon: 'custom-icon-kaoxiang',
    isCheck: false
  },
  {
    id: 22,
    color: '#c0c4cc',
    text: '电话',
    icon: 'custom-icon-dianhua',
    isCheck: false
  },
  {
    id: 23,
    color: '#c0c4cc',
    text: '晾衣架',
    icon: 'custom-icon-liangyijia',
    isCheck: false
  },
  {
    id: 24,
    color: '#c0c4cc',
    text: '电脑',
    icon: 'custom-icon-diannao',
    isCheck: false
  },
  {
    id: 25,
    color: '#c0c4cc',
    text: '鞋柜',
    icon: 'custom-icon-xiegui',
    isCheck: false
  }
]

// 公寓详情设备
export const FLAT_INSTALL_MAP = [
  { id: 1, color: '#c0c4cc', text: '书吧', icon: 'shuba', isCheck: false },
  {
    id: 2,
    color: '#c0c4cc',
    text: '活动场地',
    icon: 'huodongchangdi',
    isCheck: false
  },
  {
    id: 3,
    color: '#c0c4cc',
    text: '安全监控',
    icon: 'anquanjiankong',
    isCheck: false
  },
  {
    id: 4,
    color: '#c0c4cc',
    text: '健身房',
    icon: 'jianshenfang',
    isCheck: false
  },
  {
    id: 5,
    color: '#c0c4cc',
    text: '代收快递',
    icon: 'daishoukuaidi',
    isCheck: false
  },
  {
    id: 6,
    color: '#c0c4cc',
    text: '智能门锁',
    icon: 'zhinengmensuo',
    isCheck: false
  },
  {
    id: 7,
    color: '#c0c4cc',
    text: '停车位',
    icon: 'tingchewei',
    isCheck: false
  },
  {
    id: 8,
    color: '#c0c4cc',
    text: '便利店',
    icon: 'bianlidian',
    isCheck: false
  },
  { id: 9, color: '#c0c4cc', text: '电梯', icon: 'dianti', isCheck: false },
  { id: 10, color: '#c0c4cc', text: '吧台', icon: 'batai', isCheck: false }
]

// 合同状态
export const CONTRACT_STATUS_MAP = {
  1: '待确认',
  2: '确认中',
  3: '网签备案失败',
  4: '合同生效',
  5: '待注销',
  6: '注销中',
  7: '注销失败',
  8: '合同失效',
  9: '签约拒绝',
  10: '解约拒绝',
  11: '核验码已签约'
}

// 签约合同中间状态
export const MANAGEMENT_STATUS_MAP = {
  0: '待确认',
  1: '合同确认同意',
  2: '合同确认拒绝',
  3: '注销待确认',
  4: '注销同意',
  5: '注销拒绝'
}

// 预约签约合同状态
export const APPOINTMENT_STATUS_MAP = {
  0: '已预约',
  '-1': '预约拒绝',
  1: '预约成功',
  2: '取消预约'
}

// 合同列表状态按钮
export const CONTRACT_BUTTONS_MAP = [
  { id: 1, color: '#c0c4cc', name: '合同确认', method: 'contractConfirm' },
  { id: 2, color: '#c0c4cc', name: '注销确认', method: 'cancelConfirm' },
  { id: 3, color: '#c0c4cc', name: '合同下载', method: 'contractDownload' },
  { id: 4, color: '#c0c4cc', name: '证明下载', method: 'certificateDownload' },
  { id: 5, color: '#c0c4cc', name: '合同注销', method: 'contractCancel' },
  { id: 6, color: '#c0c4cc', name: '失败原因', method: 'failReason' },
  { id: 7, color: '#c0c4cc', name: '合同预览', method: 'contractPreview' }
]
export const COLLOCATION_NAME = [
  '电梯',
  '便利店',
  '停车场',
  '健身房',
  '活动场地',
  '安全监控',
  '书吧',
  '吧台',
  '代收快递',
  '智能门锁'
].map((item) => {
  return { value: item, label: item }
})
// 预约合同状态按钮
export const APPOINTMENT_BUTTONS_MAP = [
  { id: 1, color: '#c0c4cc', name: '预约取消', method: 'appointmentCancel' },
  { id: 2, color: '#c0c4cc', name: '预约删除', method: 'appointmentDelete' }
]

export const PROD_DOMAIN_URL = 'https://www.nnfwzl.com'
export const MINIO_DOMAIN_URL = 'https://pc.nnfwzl.com'
export const QQ_MAP_KEY = 'CXHBZ-2QHWW-NJKRC-RET2S-KJ6H7-5CFI2'

/**
 * 拆分业务-新增操作
 * @type {number}
 */
export const SPLIT_ACTION_ADD = 1

/**
 * 拆分业务-变更操作
 * @type {number}
 */
export const SPLIT_ACTION_EDIT = 2

/**
 * 拆分业务-注销操作
 * @type {number}
 */
export const SPLIT_ACTION_DEL = 3
