<template>
  <div
    class='app-main'
    :class="{ 'home-w-100vw': route.meta.title === 'home' }"
  >
    <!-- 面包屑 -->
    <div
      v-if='!route.meta.notNeedBreadcrumb'
      style='display: flex; align-items: center; color: #9f9f9f'
    >
      <div>当前位置：</div>
      <Breadcrumb />
    </div>
    <!-- 个人中心 -->
    <div class='main-wrapper'>
      <div v-if='route.meta.isUCenter' class='user-center-wrapper'>
        <div class='header-top-wrapper'>
          <div class='header-image'>
            <!-- <el-image class="image" :src="user.avatar" /> -->
            <el-image class='image' :src='userAvatar'>
              <template #error>
                <el-image
                  src='http://img0.baidu.com/it/u=1580287918,1662928404&fm=253&app=138&f=JPEG?w=500&h=586'
                >
                </el-image>
              </template>
            </el-image>
          </div>
          <div class='header-user-name'>
            <span class='name'>{{ user.realname }}</span>
            <el-divider direction='vertical' />
            <span class='logout' @click='safeLogout'>安全退出</span>
          </div>
          <div class='header-user-cert'>
            <div class='header-cert' v-if='user.faceAuth === 1'>
              <el-image
                class='image'
                src='https://pc.nnfwzl.com/rent-house/rent-platform-web/confirm3.png'
              />
              个人认证
            </div>
            <div class='header-cert' v-if='user.companyAuth === 1'>
              <el-image
                class='image'
                src='http://www.nnfwzl.com/Content/FanChan/person_pages/static/picture/confirm4.png'
              />
              企业认证
            </div>
          </div>
        </div>
        <div class=''></div>
        <div class='user-menu-wrapper'>
          <el-tabs
            v-model='tabActiveName'
            type='card'
            class='demo-tabs'
            :stretch='true'
            @tab-click='changeTabs'
          >
            <el-tab-pane label='租客' name='first'>
              <el-menu
                :default-active='tenantMenuActive'
                class='el-menu-demo'
                mode='vertical'
                @select='tenantHandleSelect'
                :router='true'
              >
                <el-menu-item index='/user/release' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Promotion />
                  </el-icon>
                  <div class='menu-name'>求租发布</div>
                </el-menu-item>
                <el-menu-item index='/user/contract' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Stamp />
                  </el-icon>
                  <div class='menu-name'>合同管理</div>
                </el-menu-item>
                <el-menu-item index='/user/appointment' class='u-menu-item'>
                  <el-icon :size='size'>
                    <BellFilled />
                  </el-icon>
                  <div class='menu-name'>预约管理</div>
                </el-menu-item>
                <el-menu-item index='/user/appointComment' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Comment />
                  </el-icon>
                  <div class='menu-name'>预约评价</div>
                </el-menu-item>
                <el-menu-item index='/user/record' class='u-menu-item'>
                  <el-icon :size='size'>
                    <List />
                  </el-icon>
                  <div class='menu-name'>备案信息</div>
                </el-menu-item>
                <el-menu-item index='/user/rent' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Opportunity />
                  </el-icon>
                  <div class='menu-name'>参考租金</div>
                </el-menu-item>
                <el-menu-item index='/user/complaint' class='u-menu-item'>
                  <el-icon :size='size'>
                    <HelpFilled />
                  </el-icon>
                  <div class='menu-name'>平台咨询</div>
                </el-menu-item>
                <el-menu-item index='/user/info' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Avatar />
                  </el-icon>
                  <div class='menu-name'>个人信息</div>
                </el-menu-item>
              </el-menu>
            </el-tab-pane>
            <el-tab-pane label='房东' name='second'>
              <el-menu
                :default-active='ownerMenuActive'
                class='el-menu-demo'
                mode='vertical'
                @select='ownerHandleSelect'
                :router='true'
              >
                <el-menu-item index='/user/house/index' class='u-menu-item'>
                  <el-icon :size='size'>
                    <HomeFilled />
                  </el-icon>
                  <div class='menu-name'>房源管理</div>
                </el-menu-item>
                <el-menu-item index='/user/contract' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Stamp />
                  </el-icon>
                  <div class='menu-name'>合同管理</div>
                </el-menu-item>
                <el-menu-item index='/user/appointment' class='u-menu-item'>
                  <el-icon :size='size'>
                    <BellFilled />
                  </el-icon>
                  <div class='menu-name'>预约管理</div>
                </el-menu-item>

                <el-menu-item index='/user/deposit' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Checked />
                  </el-icon>
                  <div class='menu-name'>托管管理</div>
                </el-menu-item>
                <el-menu-item index='/user/relet' class='u-menu-item'>
                  <el-icon :size='size'>
                    <InfoFilled />
                  </el-icon>
                  <div class='menu-name'>转租管理</div>
                </el-menu-item>
                <el-menu-item index='/user/location' class='u-menu-item'>
                  <el-icon :size='size'>
                    <LocationFilled />
                  </el-icon>
                  <div class='menu-name'>地点管理</div>
                </el-menu-item>
                <!--                <el-menu-item index="/user/split" class="u-menu-item">
                                  <el-icon :size="size"><Menu /></el-icon>
                                  <div class="menu-name">房源拆分</div>
                                </el-menu-item>-->
                <el-menu-item index='/user/rent' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Opportunity />
                  </el-icon>
                  <div class='menu-name'>参考租金</div>
                </el-menu-item>
                <!-- <el-menu-item index="/user/bind" class="u-menu-item">
                  <el-icon :size="size">
                    <Tools />
                  </el-icon>
                  <div class="menu-name">绑定企业</div>
                </el-menu-item> -->
                <!--                <el-menu-item index="/user/relet" class="u-menu-item">
                                  <el-icon :size="size">
                                    <InfoFilled />
                                  </el-icon>
                                  <div class="menu-name">转租管理</div>
                                </el-menu-item>-->
                <el-menu-item index='/user/appointLandlordList' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Comment />
                  </el-icon>
                  <div class='menu-name'>预约评价</div>
                </el-menu-item>
                <el-menu-item index='/user/complaint' class='u-menu-item'>
                  <el-icon :size='size'>
                    <HelpFilled />
                  </el-icon>
                  <div class='menu-name'>平台咨询</div>
                </el-menu-item>
                <el-menu-item index='/user/info' class='u-menu-item'>
                  <el-icon :size='size'>
                    <Avatar />
                  </el-icon>
                  <div class='menu-name'>个人信息</div>
                </el-menu-item>
              </el-menu>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class='main-content'>
        <router-view v-slot='{ Component, route }' :key='key'>
          <transition name='fade-transform' mode='out-in'>
            <keep-alive>
              <component :is='Component' :key='route.path' />
            </keep-alive>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue'
import { isTags } from '@/utils/tags'
import { generateTitle, watchSwitchLang } from '@/utils/i18n'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { logout } from '@/api/login'
import Breadcrumb from '@/components/Breadcrumb'

const store = useStore()
const route = useRoute()
const router = useRouter()

const isTenant = computed(() => store.getters.isTenant)
const user = computed(() => store.getters.userInfo)
const defaultActive = computed(() => store.getters.userMenu).value
const tenantMenuActive = ref(defaultActive)
const ownerMenuActive = ref(defaultActive)
const userAvatar = computed(() => store.getters.userAvatar)

watch(
  () => store.getters.userMenu,
  (newValue) => {
    tenantMenuActive.value = newValue
    ownerMenuActive.value = newValue
  }
)
watch(
  () => route.path,
  (val) => {
    // 首页我要出租跳转到个人中心，刷新一下tab值
    if (val === '/user/house/query' || val === '/user/house/index') {
      tabActiveName.value = 'second'
      ownerMenuActive.value = '/user/house/index'
    }
  }
)
const tabActiveName = isTenant.value ? ref('first') : ref('second')
const size = ref(18)

const key = computed(() => {
  return route.name
    ? String(route.name) + String(new Date().getTime())
    : String(route.path) + new Date().getTime()
})

const tenantHandleSelect = (key, keyPath) => {
  tenantMenuActive.value = key
}
const ownerHandleSelect = (key, keyPath) => {
  ownerMenuActive.value = key
}

const safeLogout = async () => {
  await logout()
  ElMessage.success('退出成功！')
  store.dispatch('user/logout')
}

const changeTabs = (value) => {
  if (value.props.name !== 'second') {
    tenantMenuActive.value = defaultActive
    router.push('/user/release')
  } else {
    ownerMenuActive.value = defaultActive
    router.push('/user/house/index')
  }
  store.commit('user/setTenant', value.props.name !== 'second')
}

/**
 * 生成 title
 */
const getTitle = (route) => {
  let title = ''
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = generateTitle(route.meta.title)
  }
  return title
}

/**
 * 监听路由变化
 */
watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    })
  },
  {
    immediate: true
  }
)

/**
 * 国际化 tags
 */
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((route, index) => {
    store.commit('app/changeTagsView', {
      index,
      tag: {
        ...route,
        title: getTitle(route)
      }
    })
  })
})
</script>

<style lang='scss' scoped>
.app-main {
  min-height: calc(100vh - 283px);
  width: 1280px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;

  .main-wrapper {
    display: flex;
    height: 100%;

    .user-center-wrapper {
      background-color: #ffffff;
      min-width: 200px;
      height: auto;
      // box-shadow: 0 0 10px #dcdfe6;
      border: solid 1px #ebeef5;
      margin: 0 10px 0 0;

      .header-top-wrapper {
        .header-image {
          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            margin: 20px;
            height: 100px;
            width: 100px;
            border-radius: 50%;
          }
        }

        .header-user-name {
          display: flex;
          align-items: center;
          justify-content: center;

          .name {
            margin-right: 5px;
            font-weight: 800;
          }

          .logout {
            font-size: 15px;

            &:hover {
              cursor: pointer;
              color: #409eff;
            }
          }
        }

        .header-user-cert {
          display: flex;
          align-items: center;
          justify-content: center;

          .header-cert {
            margin: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;

            .image {
              height: 20px;
              width: 22px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .main-content {
      flex: 1;
      background: #ffffff;
      // box-shadow: 0 0 10px #dcdfe6;
    }
  }
}

.el-menu {
  border-right: none;
}

.u-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #e5e5e5;

  .menu-name {
    line-height: 61px;
    height: 56px;
    margin-left: 8px;
  }
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-left: none;
  border-right: none;
  border-radius: none;
}

.home-w-100vw {
  width: 100%;
}
</style>
