import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
import UserCenterRouter from './modules/UserCenter'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [UserCenterRouter]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    meta: {
      title: 'home'
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/home/index'),
        meta: {
          title: 'home',
          notNeedBreadcrumb: true
        }
      },
      {
        path: '/house/list',
        name: 'houseList',
        component: () =>
          import(/* webpackChunkName: "houseList" */ '@/views/house/index'),
        meta: {
          title: 'houseList',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/house/detail',
        name: 'houseDetail',
        component: () =>
          import(/* webpackChunkName: "houseDetail" */ '@/views/house/detail'),
        meta: {
          title: 'houseDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/house/check',
        name: 'houseCheck',
        component: () =>
          import(/* webpackChunkName: "house" */ '@/views/house/houseCheck'),
        meta: {
          title: 'houseCheck',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/enterpriseInfo/list',
        name: 'enterpriseInfo',
        component: () =>
          import(
            /* webpackChunkName: "enterpriseInfo" */ '@/views/enterprise-info/index'
          ),
        meta: {
          title: 'enterpriseInfo',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/enterpriseInfo/detail/:fname',
        name: 'enterpriseDetail',

        component: () =>
          import(
            /* webpackChunkName: "enterpriseInfo" */ '@/views/enterprise-info/detail'
          ),
        meta: {
          isProps: true, // 携带参数路由
          title: 'enterpriseDetail',
          notNeedBreadcrumb: false
        }
      },

      {
        path: '/enterpriseInfo/agent/:id',
        name: 'agentDetail',

        component: () =>
          import(
            /* webpackChunkName: "enterpriseInfo" */ '@/views/enterprise-info/agent'
          ),
        meta: {
          isProps: true, // 携带参数路由
          title: 'agentDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/enterpriseInfo/store/:id',
        name: 'storeDetail',

        component: () =>
          import(
            /* webpackChunkName: "enterpriseInfo" */ '@/views/enterprise-info/store'
          ),
        meta: {
          isProps: true, // 携带参数路由
          title: 'storeDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/supportServer',
        name: 'supportServer',

        component: () =>
          import(
            /* webpackChunkName: "supportServer" */
            '@/views/supportServer/index'
          ),
        meta: {
          title: 'supportServer',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/login/index'),
        meta: {
          title: 'login',
          notNeedBreadcrumb: true
        }
      },

      {
        path: '/loginByCas',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/login/indexByCas'),
        meta: {
          title: 'login',
          notNeedBreadcrumb: true
        }
      },

      {
        path: '/rent',
        component: () =>
          import(/* webpackChunkName: "rent" */ '@/views/rent/index'),
        meta: {
          title: 'rentalInNeed',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/info',
        component: () =>
          import(/* webpackChunkName: "info" */ '@/views/info/index'),
        meta: {
          title: 'info',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/notice',
        component: () =>
          import(/* webpackChunkName: "notice" */ '@/views/notice/index'),
        meta: {
          title: 'notice',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/creditEvaluate',
        component: () =>
          import(
            /* webpackChunkName: "notice" */ '@/views/creditEvaluate/index'
          ),
        meta: {
          title: 'creditEvaluate',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/register',
        component: () =>
          import(/* webpackChunkName: "register" */ '@/views/register/index'),
        meta: {
          title: 'register',
          notNeedBreadcrumb: true
        }
      },
      {
        path: '/forgetThePassword',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '@/views/forget-the-password'
          ),
        meta: {
          title: 'forgetThePassword',
          notNeedBreadcrumb: true
        }
      },
      {
        path: '/apartment/detail', // 集中式公寓详情页
        name: 'apartmentDetail',
        component: () =>
          import(/* webpackChunkName: "register" */ '@/views/apartment/detail'),
        meta: {
          title: 'apartmentDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/apartment/houseDetail', // 集中式公寓房源信息
        name: 'apartmentHouseDetail',
        component: () =>
          import(
            /* webpackChunkName: "register" */ '@/views/apartment/houseDetail'
          ),
        meta: {
          title: 'houseDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      },
      {
        path: '/FAQ',
        name: 'FAQ',
        component: () =>
          import(
            /* webpackChunkName: "FAQ" */
            '@/views/FAQ/index'
          ),
        meta: {
          title: 'FAQ',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/FAQ/help',
        name: 'help',
        component: () =>
          import(
            /* webpackChunkName: "FAQ" */
            '@/views/FAQ/help'
          ),
        meta: {
          title: 'help',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/FAQ/download',
        name: 'download',
        component: () =>
          import(
            /* webpackChunkName: "FAQ" */
            '@/views/FAQ/download'
          ),
        meta: {
          title: 'download',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/article',
        name: 'articleDetail',
        component: () =>
          import(
            /* webpackChunkName: "FAQ" */
            '@/views/notice/details'
          ),
        meta: {
          title: 'articleDetail',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/auth',
        name: 'qrCode',
        component: () =>
          import(
            /* webpackChunkName: "qrCode" */
            '@/views/auth/qrCode'
          ),
        meta: {
          title: 'auth',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/cert',
        name: 'uploadCert',
        component: () =>
          import(
            /* webpackChunkName: "uploadCert" */
            '@/views/cert/index'
          ),
        meta: {
          title: 'uploadCert',
          notNeedBreadcrumb: false
        }
      },
      {
        path: '/changePwd',
        name: 'changePwd',
        component: () =>
          import(
            /* webpackChunkName: "changePwd" */
            '@/views/changePwd/index'
          ),
        meta: {
          title: 'changePwd',
          notNeedBreadcrumb: false
        }
      }
    ]
  },
  {
    path: '/mapSearch',
    name: 'mapSearch',
    component: () =>
      import(
        /* webpackChunkName: "mapSearch" */
        '@/views/mapSearch/index'
      ),
    meta: {
      title: 'mapSearch',
      notNeedBreadcrumb: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(
        /* webpackChunkName: "mapSearch" */
        '@/views/logout/index'
      ),
    meta: {
      title: 'mapSearch',
      notNeedBreadcrumb: true
    }
  },
  {
    path: '/trans',
    name: 'trans',
    component: () =>
      import(
        /* webpackChunkName: "mapSearch" */
        '@/views/login/trans'
      ),
    meta: {
      title: 'trans',
      notNeedBreadcrumb: true
    }
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: () =>
      import(
        /* webpackChunkName: "mapSearch" */
        '@/views/authorization/index'
      ),
    meta: {
      title: 'authorization',
      notNeedBreadcrumb: true
    }
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
  }
}

console.log('环境变量' + process.env.NODE_ENV)
const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: [...publicRoutes, ...privateRoutes]
})

export default router
