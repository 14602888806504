import request from '@/utils/request'

/**
 * 获取图形验证码
 */
export const randomImage = (data) => {
  return request({
    url: `/sys/randomImage/${data.key}`
  })
}

/**
 * 登录接口
 */
export const rentLogin = (data) => {
  return request({
    url: '/sys/rentLogin',
    method: 'POST',
    data
  })
}

/**
 * 物业登录接口
 */
export const propertyLogin = (data) => {
  return request({
    url: '/enterprise/loginProperty',
    params: data
  })
}

/**
 * 企业管理登录接口
 */
export const entManageLogin = (data) => {
  return request({
    url: '/enterprise/loginEnterprise',
    params: data
  })
}

/**
 * 企业登录接口
 */
export const entLogin = (data) => {
  return request({
    url: '/enterprise/login',
    params: data
  })
}

/**
 * 绑定接口
 */
export const bindEnterprise = (data) => {
  return request({
    url: '/enterprise/bindEnterprise',
    params: data
  })
}
/**
 * 查询当前用户企业名字
 */
export const queryUserCompanyName = () => {
  return request({
    url: '/enterprise/queryUserCompanyName'
  })
}
/**
 * 绑定企业接口
 * @param data {companyId}
 * @returns {*}
 */
export const unBindEnterprise = (data) => {
  return request({
    url: '/enterprise/unbindEnterprise',
    params: data
  })
}

/**
 * 登出
 */
export const logout = (data) => {
  return request({
    url: '/sys/logout',
    method: 'POST',
    data
  })
}

/**
 * 统一用户中心登录验证
 */
export const loginByCas = (param) => {
  return request({
    url: '/sys/cas/client/validateLogin?' + param,
    method: 'GET'
  })
}
