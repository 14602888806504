import { MAIN_COLOR } from '@/constant'
import { getItem } from '@/utils/storage'
import { generateColors } from '@/utils/theme'

const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  userAvatar: (state) => {
    const userInfo = state.user.userInfo
    if (userInfo.avatar) {
      return userInfo.avatar.startsWith('http')
        ? userInfo.avatar
        : state.app.minioUrl + userInfo.avatar
    }
    return ''
  },

  isTenant: (state) => state.user.isTenant,
  warrantData: (state) => state.house.warrantData,
  userMenu: (state) => state.user.userMenu,
  /**
   * @returns true 表示已存在用户信息
   */
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  cssVar: (state) => {
    return {
      ...state.theme.variables,
      ...generateColors(getItem(MAIN_COLOR))
    }
  },
  sidebarOpened: (state) => state.app.sidebarOpened,
  language: (state) => state.app.language,
  mainColor: (state) => state.theme.mainColor,
  tagsViewList: (state) => state.app.tagsViewList,
  // 企业认证状态 0:未认证 1:企业员工 2企业管理员
  companyAuth: (state) => state.user.userInfo.companyAuth,
  sourceList: (state) => state.app.source
}
export default getters
